<template>
  <a
    class="text-teal underline"
    :class="{ 'whitespace-nowrap': preventWrap }"
    target="_blank"
    rel="noopener"
  >
    <slot></slot>
    <IconExternalLink
      alt="Open's in a new tab"
      class="s-externalLinkIcon inline-block"
      width="12"
      height="12"
    />
  </a>
</template>

<script>
  export default {
    props: {
      preventWrap: {
        type: Boolean,
        default: true
      }
    }
  }
</script>

<style scoped lang="postcss">
  .s-externalLinkIcon {
    @apply w-3 h-3;

    margin-bottom: 1px;
    margin-left: 1px;
  }
</style>
