import env from '@/environment'

const GOOGLE_MAPS_URL = 'https://maps.google.com'

export const addressToGoogleMapLink = (address, includeCompanyName = true) => {
  const requiredKeys = ['street_1', 'street_2', 'city', 'state', 'zip']

  const addressData = Object.entries(address || {}).reduce(
    (str, [key, val]) => {
      if (requiredKeys.includes(key) && typeof val === 'string') {
        str.push(val)
      }
      return str
    },
    [includeCompanyName ? env.COMPANY_NAME : '']
  )

  if (addressData.length <= 1) return false

  const url = new URL(GOOGLE_MAPS_URL)
  url.searchParams.append('q', addressData.join(' '))
  return url.href
}
