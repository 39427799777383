<template>
  <li
    class="flex-col border-2 rounded bg-white"
    :class="{
      'border-pink-400': expanded,
      'border-gray-200': !expanded,
      'cursor-pointer selectable': !expanded && !disabled,
      'opacity-40': disabled
    }"
    @click="clickItem"
  >
    <div>
      <div class="px-5 py-5 flex gap-x-6">
        <address class="w-8/12 not-italic">
          <strong class="font-bold text-base leading-none">{{ header }}</strong>
          <slot name="address"></slot>
        </address>
        <div
          v-if="disabled"
          class="w-4/12 flex items-center justify-end"
        >
          <span class="border rounded-md px-2 py-1">Unavailable</span>
        </div>
        <div
          v-if="expanded"
          class="w-4/12 flex justify-end"
        >
          <SalveButton
            v-if="!selected"
            size="sm"
            @click.stop="$emit('clickShop')"
          >
            <span class="flex items-center">
              <span class="pr-1.5 text-xs uppercase"> Select </span>
              <IconArrowRight width="5" />
            </span>
          </SalveButton>
          <div
            v-else
            class="border border-teal text-teal rounded-full px-3 font-medium font-main h-8 flex items-center"
          >
            <IconCheckmark width="15" />
            <span class="pl-1.5 text-xs uppercase">Selected</span>
          </div>
        </div>
      </div>
    </div>
    <template v-if="expanded">
      <div class="px-5 py-5 border-t">
        <ol class="grid gap-x-4 grid-cols-2">
          <slot></slot>
        </ol>
      </div>
      <div
        v-if="footer"
        class="bg-pink-50 text-pink-400"
      >
        <div class="px-5 py-2 flex gap-x-1">
          <IconClock
            width="14"
            height="14"
            class="flex-shrink-0"
          />
          <span class="leading-tight">{{ footer }}</span>
        </div>
      </div>
    </template>
  </li>
</template>

<script>
  export default {
    props: {
      disabled: {
        type: Boolean,
        default: false
      },
      expanded: {
        type: Boolean,
        default: false
      },
      selected: {
        type: Boolean,
        default: false
      },
      header: {
        type: String,
        default: ''
      },
      footer: {
        type: String,
        default: ''
      }
    },
    emits: ['clickItem', 'clickShop'],
    methods: {
      clickItem() {
        if (!this.disabled) this.$emit('clickItem')
      }
    }
  }
</script>

<style lang="postcss" scoped>
  .selectable {
    background-image: url('assets/icon/svg/chevron-pink.svg');
    background-position: calc(100% - theme('spacing.6')) center;
    background-repeat: no-repeat;
    background-size: theme('spacing.3');
  }
</style>
