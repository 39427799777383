<template>
  <PlaceListPlace
    :header="location.name"
    :footer="pickupInfo.customerPickupLabel"
    :expanded="expanded"
    :selected="selected"
    :disabled="disabled"
    @click-item="$emit('clickItem')"
    @click-shop="$emit('clickShop')"
  >
    <template
      v-if="mapLink"
      #address
    >
      <ExternalLink
        :href="mapLink"
        title="View on Google Maps"
        class="block"
      >
        {{ location.address.street_1
        }}<template v-if="location.address.street_2"><br />{{ location.address.street_2 }}</template>
      </ExternalLink>
    </template>

    <li
      v-for="day of location.schedule"
      :key="day.day_of_week"
      class="inline-flex justify-between max-w-max"
    >
      <span class="w-8">{{ day._computed.date.format('ddd') }}</span>
      <span
        v-if="day.has_pick_up"
        class="text-teal"
      >
        {{ day._computed.pickup_start.format('h:mm') }}-{{ day._computed.pickup_end.format('h:mm') }}
      </span>
      <span
        v-else
        class="text-gray-600"
      >
        Closed
      </span>
    </li>
  </PlaceListPlace>
</template>

<script>
  import { usePickupInfo } from '@/store/region/utilities/pickup'
  import { addressToGoogleMapLink } from '@/utils/maps'

  export default {
    name: 'PickupLocation',

    props: {
      location: {
        type: Object,
        required: true
      },
      expanded: {
        type: Boolean,
        default: false
      },
      selected: {
        type: Boolean,
        default: false
      }
    },
    emits: ['clickItem', 'clickShop'],
    computed: {
      pickupInfo() {
        return usePickupInfo(this.location.schedule || [])
      },
      disabled() {
        return !this.location.schedule || !this.pickupInfo.nextPickup
      },
      mapLink() {
        return addressToGoogleMapLink(this.location.address)
      }
    }
  }
</script>
