<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 21 21"
  >
    <path
      fill="currentColor"
      d="M10.3772 21c5.7312 0 10.3773-4.701 10.3773-10.5 0-.90379-.1129-1.7809-.3251-2.61771-.3081.24404-.6601.43391-1.0421.55543.1477.66347.2257 1.35367.2257 2.06228 0 5.1611-4.135 9.345-9.2358 9.345-5.10072 0-9.2357-4.1839-9.2357-9.345s4.13498-9.345 9.2357-9.345c2.0834 0 4.0056.69796 5.5508 1.87539.2615-.29747.5748-.54733.9255-.73513C15.0784.858875 12.827 0 10.3772 0 4.64605 0 0 4.70101 0 10.5 0 16.299 4.64605 21 10.3772 21Z"
      clip-rule="evenodd"
    />
    <path
      fill="currentColor"
      d="M20.7819 3.21967c.2894.29289.2894.76777 0 1.06066L10.5958 14.5869c-.0429.0608-.0957.1162-.1579.1636-.3269.2493-.791.1841-1.03657-.1457L5.54199 9.42272c-.24557-.32973-.17961-.79912.14733-1.0484.32694-.24928.79105-.18406 1.03662.14568l3.33866 4.483 9.669-9.78333c.2895-.29289.7588-.29289 1.0483 0Z"
      clip-rule="evenodd"
    />
  </svg>
</template>

<script>
  export default {
    name: 'CheckmarkIcon'
  }
</script>
